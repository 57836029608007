@keyframes loader {
    0% { transform: translate(12px,80px) scale(0); }
   25% { transform: translate(12px,80px) scale(0); }
   50% { transform: translate(12px,80px) scale(1); }
   75% { transform: translate(80px,80px) scale(1); }
  100% { transform: translate(148px,80px) scale(1); }
 }
 @keyframes loader-r {
    0% { transform: translate(148px,80px) scale(1); }
  100% { transform: translate(148px,80px) scale(0); }
 }
 @keyframes loader-c {
    0% { background: #40499D }
   25% { background: #6BA989 }
   50% { background: #FFE2E5 }
   75% { background: #BFAF25 }
  100% { background: #DC2626 }
 }
 .page-cover {
     height: 100vh;
     box-sizing: border-box;
     display: flex;
     justify-content: center;
     align-items: center;
     align-content: center;
   }  
 .loader div {
   align-self: center;
   position: absolute;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   transform: translate(60px,60px) scale(1);
   background: #40499D;
   animation: loader 1s infinite cubic-bezier(0,0.5,0.5,1);
 }
 .loader div:nth-child(1) {
   background: #40499D;
   transform: translate(148px,60px) scale(1);
   animation: loader-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), loader-c 1s infinite step-start;
 }.loader div:nth-child(2) {
   animation-delay: -0.25s;
   background: #6BA989;
 }.loader div:nth-child(3) {
   animation-delay: -0.5s;
   background: #FFE2E5;
 }.loader div:nth-child(4) {
   animation-delay: -0.75s;
   background: #BFAF25;
 }.loader div:nth-child(5) {
   animation-delay: -1s;
   background: #DC2626;
 }
 .loading-spinner-ellipsis-lb {
   width: 200px;
   height: 200px;
   display: inline-block;
   overflow: hidden;
   /* background: #ffffff; */
   align-self: center;
 }
 .loader {
   width: 100%;
   height: 100%;
   position: relative;
   transform: translateZ(0) scale(1);
   backface-visibility: hidden;
   transform-origin: 0 0; /* see note above */
 }
 .loader div { box-sizing: content-box; }
 /* generated by https://loading.io/ */
 