.timer-container {
    width: 138px;
    height: 50px;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    transition: background-color 1s ease-in-out;
  }
  
  .timer-bar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .yellow-bar {
    height: 100%;
    background-color: yellow;
    transition: width 1s ease-in-out, background-color 1s ease-in-out;
  }
  
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 800;
    align-items:center;
    display: flex;
  }
  .time-label {
    margin-right: 8px; 
    font-size: 15px;
}
  