
.page-cover {
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }  
.loader-img-div{
  width: 300px;
  height: 150px; /*used to be 120px with preloader that has bg*/
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.loader-img{
  width: auto;
  height: 100%;
  object-fit: contain;
}
.inner-page-div{
  width: 300px;
  text-align: center;
  font-size: 12px;
}
.random-text-div{
  padding: 10px 0px;
}
.random-text-div>p{
  opacity: 0; 
  animation: fadeIn 2s ease-in-out forwards;; 
}
@keyframes fadeIn {
  0% {
      opacity: 0; 
  }
  100% {
      opacity: 1;
  }
}
