.image-container {
    position: relative;
  }
  
  .fade {
    opacity: 1;
    transition: opacity 0.4s ease-in-out; /* Adjust the duration and easing as needed */
  }
  
  .fade-out {
    opacity: 0.4;
  }

.socialmedia-links:hover{
  color:yellow
} 
  