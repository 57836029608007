@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");
@import url("https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Custom styles */

@layer utilities {
  /* Font Styles */
  .e-study-home-dark {
    color: #303030;
  }

  .e-study-heading-one {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 47px;
  }

  .e-study-heading-two {
    font-family: "DM Sans", sans-serif;
    /* font-style: normal; */
    font-size: 24px;
    line-height: 31px;
  }

  .e-study-heading-two-bold {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }

  .e-study-heading-three {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
  }

  .e-study-heading-four {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
  }

  .e-study-heading-five {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
  }

  .e-study-subtitle-one {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }

  .e-study-subtitle-two {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }

  .e-study-subtitle-three {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }

  .e-study-normal-text-one {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  .e-study-normal-text-two {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .e-study-normal-text-three {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .e-study-small-text-one {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  .e-study-small-text-two {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .e-study-small-text-three {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }

  .e-study-paragraph-text-one {
    font-family: "DM Sans", sans-serif;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
  }

  /* Color Styles */
  .e-study-bg-primary {
    background-color: #40499d;
  }

  .e-study-primary {
    color: #40499d;
  }

  .e-study-primary-blur {
    color: #40499d;
    opacity: 40%;
  }

  .e-study-bg-rectangle-gray {
    background-color: #e5e5e5;
  }

  .e-study-black {
    color: #313131;
  }
  .e-study-school-black {
    color: #45464e;
  }

  .e-study-text-grey {
    color: #919191;
  }

  .e-study-bg-active-green {
    background-color: #05765f;
  }

  .e-study-active-green {
    color: #05765f;
  }

  .estudy-bg-inactive-red {
    background-color: #f15046;
  }

  .estudy-inactive-red {
    color: #f15046;
  }

  .estudy-bg-profile {
    background-color: #fff2eb;
  }
  .estudy-text-profile {
    color: #861713;
  }

  /* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
      box-shadow: none !important;
    } */

  /* Component Styles */
  /* Button */
  .e-study-button-blue {
    background-color: #40499d;
    color: #ffffff;
  }
  .e-study-button-lilac {
    color: #1347f5;
    background: rgba(85, 112, 241, 0.08);
  }

  .e-study-button-black:hover {
    background-color: #40499d;
    color: #ffffff;
  }

  .e-study-button-black {
    background-color: transparent;
    color: #313131;
    border: 1px solid #313131;
  }

  .e-study-button-green {
    background-color: transparent;
    color: #2e7d32;
    border: 1px solid #2e7d32;
  }

  .e-study-button-green:hover {
    background-color: #2e7d32;
    color: #ffffff;
  }

  .e-study-button-danger {
    background-color: transparent;
    color: #fa7e77;
    border: 1px solid #fa7e77;
  }

  .e-study-button-danger:hover {
    background-color: #fa7e77;
    color: #ffffff;
  }

  /* React WYSIWYG Editor */
  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }

  .editor-class {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ccc;
    height: auto !important;
    white-space: pre-line;
  }

  .toolbar-class {
    border: 1px solid #ccc;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

*::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
  cursor: pointer;
}

*::-webkit-scrollbar:hover {
  opacity: 1;
}

*::-webkit-scrollbar-track {
  background: #d1d2d5;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  border: 1px solid grey;
  cursor: pointer;
}

.inputBtn {
  margin-top: 5px;
  background-color: #858693;
  width: 35%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.addColSpan {
  grid-column: span 2;
}

.grid-item {
  background-color: #e0e0e0;
  border: 1px solid gray;
  padding: 20px;

  /* font-size: 30px; */
  text-align: center;
}

.operations {
  background-color: #d88138;
}

::backdrop {
  background-color: white;
}

.quill {
  /* min-height: 20rem; */
  max-height: 20rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 12px 1px;
}

.ql-container {
  height: 8rem;
}

.quill::-webkit-scrollbar {
  display: none;
}

.slick-slide > div {
  margin: 0 10px 0 0;
  box-sizing: border-box;
}

/* Uncomment this to disable copy and paste in the solution */
/* .no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */

.quill {
  max-height: 20rem;
  display: flex;
  flex-direction: column;
  margin: 12px 1px;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.ql-container {
  height: 100%;
  border: none;
  overflow-y: auto;
  font-family: "DM Sans", sans-serif !important;
}

.ql-container p{
  font-size: 14px !important;
  line-height: 24px !important;
}

