
.hide-badge {
    opacity: 0;
    transform: translateY(0); 
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .show-badge {
    opacity: 1;
    transform: translateY(0);
  }
  
  .hide-sound {
    opacity: 0;
    transform: translateY(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .show-sound {
    opacity: 1;
    transform: translateY(0);
  }

  .hide-content {
    opacity: 0;
    transform: translateY(-30px); 
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .show-content {
    opacity: 1;
    transform: translateY(30px);
  }
  
  .hide-content2 {
    opacity: 0;
    transform: translateY(0px); 
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .show-content2 {
    opacity: 1;
    transform: translateY(-30px);
  }
  